.cc-prodTile-imageContainer-carousel {
    .slick-list,
    .slick-track {
        height: 100%;
        max-height: 100%;
    }

    .slick-arrow {
        background-color: $white;
        border-radius: rem-calc(24);
        border: rem-calc(1) solid $cloud;
        height: rem-calc(24);
        opacity: 0;
        padding: rem-calc(0 8);
        transition: opacity 200ms ease-in-out;
        width: rem-calc(32);
        z-index: $zIndex-carouselArrow;

        &::before {
            color: $charcoal;
            content: url('../images/arrow-left-productTile-slick.svg');
            display: block;
            font-size: unset;
            height: rem-calc(16);
            line-height: unset;
            opacity: 1;
            width: rem-calc(16);
        }

        &.slick-prev {
            left: rem-calc(8);
        }

        &.slick-next {
            right: rem-calc(8);
            &::before {
                transform: rotate(180deg);
            }
        }
    }

    .slick-dots {
        @include flexGenerator(row, flex-start, center, 0 0 auto);
        bottom: rem-calc(17.6);
        gap: rem-calc(4);
        left: rem-calc(8);
        max-width: calc(100% - #{rem-calc(8)});
        width: auto;
        li {
            width: auto;
            margin: 0;
            opacity: 0.3;
            &.slick-active {
                opacity: 1;
            }

            > button {
                width: rem-calc(4);
                height: rem-calc(4);
                background-color: $white;
                outline: rem-calc(0.5) solid $fossil;
                border-radius: 50%;
                &::before {
                    display: none;
                }
            }
        }
    }
    &:hover {
        .slick-arrow {
            opacity: 1;
        }
    }
}
