.cc-prodTile {
    @include flexGenerator(column);
    gap: rem-calc(12);

    &-name {
        @include truncateRow(2);
    }

    &-badge {
        margin-top: rem-calc(8);
    }

    &-colors {
        @include flexGenerator(row, flex-start, center);
        gap: rem-calc(4);
        font-size: rem-calc(12);
        letter-spacing: 0.04em;
        line-height: rem-calc(13.66);
        padding-top: rem-calc(12);
        &-colorSwatch {
            border-radius: 50%;
            height: rem-calc(9.35);
            width: rem-calc(9.35);
            &_selected {
                border: rem-calc(1) solid rgba(60, 55, 56, 0.4);
                height: rem-calc(12.22);
                padding: rem-calc(1.53);
                width: rem-calc(12.22);
            }
        }
    }

    &-isGreen {
        margin-top: rem-calc(12);
        margin-bottom: rem-calc(0);
    }

    //PLP VARIANT
    &_big {
        grid-column: span 2;
    }

    .image-wrapper {
        display: block;
    }
}
