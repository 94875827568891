.cc-prodTile-imageContainer {
    position: relative;
    aspect-ratio: 2/3;

    &-carousel {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &-image {
            display: none;
        }
        & > &-image:first-child {
            display: block;
        }

        &.slick-slider {
            position: absolute;
        }
    }
    &-image {
        aspect-ratio: 2/3;
        border-radius: rem-calc(4);
        inset: 0px;
        object-fit: cover;
        object-position: center;
        overflow: hidden;
        width: 100%;
    }
    &-label {
        background-color: $off-white;
        border-radius: rem-calc(2);
        color: $charcoal;
        left: rem-calc(8);
        padding: rem-calc(2 6);
        position: absolute;
        top: rem-calc(8);
        @include breakpoint(large up) {
            padding: rem-calc(4 8);
        }
    }
    &-card {
        position: absolute;
        bottom: rem-calc(8);
        width: calc(100% - #{rem-calc(16)});
        left: rem-calc(8);
        padding: rem-calc(8);
        border: rem-calc(1) solid $cloud;
        border-radius: rem-calc(4);
        display: flex;
        gap: rem-calc(13);
        @include breakpoint(large up) {
            gap: rem-calc(16);
            width: calc(100% - #{rem-calc(32)});
            left: rem-calc(16);
            bottom: rem-calc(16);
        }

        &-image {
            flex: 0 0 auto;
            width: rem-calc(41);
            border-radius: rem-calc(2);
            @include breakpoint(large up) {
                width: rem-calc(60);
            }
        }

        &-info {
            flex: 1 1 0;

            &-name {
                margin-bottom: rem-calc(4);
            }

            .cc-omnibusStack {
                margin-top: rem-calc(4);
                @include breakpoint(large up) {
                    margin-top: 0;
                }
                &-sales {
                    display: none;
                }
                &-item {
                    &_regularPrice {
                        display: none;
                    }
                }
            }
        }
    }
    // variant
    &_big {
        aspect-ratio: 3/2;

        @include breakpoint(large up) {
            aspect-ratio: unset;
            &::before {
                content: '';
                display: block;
                padding-bottom: calc(75% - #{rem-calc(12)});
                width: 100%;
            }
        }

        .cc-prodTile-imageContainer-image {
            aspect-ratio: 3/2;

            @include breakpoint(large up) {
                aspect-ratio: unset;
                height: 100%;
                margin: auto;
                max-height: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                width: auto;
            }
        }
    }

    &_unavailable {
        .cc-prodTile-imageContainer-image {
            opacity: 50%;
        }

        &-label {
            align-items: center;
            align-self: stretch;
            background-color: $white;
            border-radius: rem-calc(2);
            border: rem-calc(1) solid $cloud;
            bottom: rem-calc(8);
            color: $charcoal;
            display: flex;
            gap: rem-calc(8);
            justify-content: center;
            left: rem-calc(8);
            padding: rem-calc(4 8);
            position: absolute;
            right: rem-calc(8);

            @include breakpoint(large up) {
                padding: rem-calc(8);
            }
        }
    }
}
