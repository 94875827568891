.cc-prodTile-quickAdd {
    &-button {
        @include increaseButtonSize();
        background-color: $white;
        border-radius: 50%;
        bottom: rem-calc(8);
        color: $charcoal;
        display: flex;
        padding: rem-calc(5);
        position: absolute;
        right: rem-calc(8);
        z-index: $zIndex-carouselArrow;

        &_completePromo {
            right: rem-calc(16);
            bottom: rem-calc(16);
            @include breakpoint(large up) {
                right: rem-calc(24);
                bottom: rem-calc(24);
            }
        }
    }
    &-modal {
        @include flexGenerator(column-reverse, flex-end);
        bottom: 0;
        display: none;
        height: 100%;
        left: 0;
        max-height: 100%;
        max-width: 100%;
        overflow: auto;
        position: fixed;
        width: 100%;
        z-index: $zIndex-quickAddModal;

        &_promo {
            z-index: $zIndex-quickAddAboveRightModal;
        }

        @include breakpoint(large up) {
            position: absolute;
            z-index: $zIndex-simpleUpper;
        }

        &-container {
            @include flexGenerator(column, flex-start, stretch, 0 0 auto);
            // @include topBarMobileModal;

            background-color: $white;
            border-radius: rem-calc(4);
            border: rem-calc(1) solid rgba(0, 0, 0, 0.2);
            gap: rem-calc(16);
            max-height: 100%;
            overflow-y: auto;
            padding: rem-calc(48 16 16);
            position: relative;

            @include breakpoint(large up) {
                padding: rem-calc(16);
            }
        }

        &-outter {
            flex-grow: 1;

            @include breakpoint(large up) {
                background-color: transparent;
            }
        }

        &-closeButton {
            display: block;
            position: absolute;
            right: rem-calc(16);
            top: rem-calc(16);
        }

        &-heading {
            margin-bottom: rem-calc(-12);
        }

        &-selectedSize {
            align-items: center;
            column-gap: rem-calc(8);
            display: inline-flex;
            @include breakpoint (small down){
                width: auto;
                white-space: nowrap;
            }
        }

        &-nameProduct {
            text-transform: capitalize;
        }

        &-sizes {
            @include flexGenerator(row, flex-start, center);
            flex-wrap: wrap;
            margin: rem-calc(0 -4);
            row-gap: rem-calc(8);

            &-button {
                @include flexGenerator(row, center, center, 1 0 auto);

                border-radius: rem-calc(2);
                border: rem-calc(1) solid $cloud;
                gap: rem-calc(4);
                height: rem-calc(40);
                margin: rem-calc(0 4);
                max-width: calc(25% - #{rem-calc(8)});
                padding: rem-calc(11 8.5);
                white-space: nowrap;
                width: calc(25% - #{rem-calc(8)});

                // show 5 element per row, only on bigger screen
                @include breakpoint(xxlarge up) {
                    width: calc(20% - #{rem-calc(8)});
                    max-width: calc(20% - #{rem-calc(8)});

                    .cc-completeYourPromo-productCards & {
                        width: calc(25% - #{rem-calc(8)});
                        max-width: calc(25% - #{rem-calc(8)});
                    }
                }

                @mixin selectHoverSizeButton() {
                    background-color: $charcoal;
                    border: none;
                    color: $white;
                    transition: all 0.3s ease-out;
                }

                &_selected {
                    @include selectHoverSizeButton();
                }

                @include breakpoint(large up) {
                    &:hover:not(.cc-prodTile-quickAdd-modal-sizes-button_disabled) {
                        @include selectHoverSizeButton();
                    }
                }

                &_disabled {
                    color: $fossil;
                }
            }
            &-lowAvailability {
                background-color: $mocha;
                border-radius: 50%;
                height: rem-calc(4);
                width: rem-calc(4);
            }
        }

        &-lowAvailability {
            @include flexGenerator(row, flex-start, center);
            gap: rem-calc(4);
            @include breakpoint (small down){
                width: auto;
                white-space: nowrap;
            }
        }

        &-confirmButton {
            flex-grow: 1;
            height: rem-calc(44);
            min-height: rem-calc(44);

            @include breakpoint(large up) {
                display: none;
            }
        }
    }
}
