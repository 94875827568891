.cc-notifyMeModal {
    z-index: 1049; /* Find in store modal is 1050 */

    .cc-notifyMeModal-container-inner {
        padding: rem-calc(28 16 32 16);
        @include breakpoint(large up) {
            padding: rem-calc(48 32 16 32);
            flex: 1 0 auto;
        }
    }

    &-close {
        @include increaseButtonSize();
        position: absolute;
        top: rem-calc(16);
        right: rem-calc(16);
        cursor: pointer;
    }

    &-image {
        width: 53.35276967930029%;
        border-radius: rem-calc(4);
        margin-bottom: rem-calc(16);
        @include breakpoint(large up) {
            width: 50%;
            margin-bottom: rem-calc(32);
        }
    }

    &-notifyBtn {
        margin: rem-calc(8 0 16);
        flex: none;
    }

    &-findInStoreBtn {
        flex: 0 0 auto;
        margin: rem-calc(0 16 32 16) !important;
        @include breakpoint(large up) {
            margin-bottom: rem-calc(16) !important;
        }
        &.cc-customIcon:not(:focus) {
            .button-icon-svg {
                color: $mocha;
            }
        }
        .cc-hide-findInStoreButton & {
            display: none;
        }
    }

    &-size {
        margin-bottom: rem-calc(16);
        @include breakpoint(large up) {
            margin-top: rem-calc(8);
        }
    }

    &-privacy {
        display: block;
        margin-top: rem-calc(16);
        @include breakpoint(large up) {
            margin-top: rem-calc(24);
        }
    }

    &-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        flex: 1 0 auto;
    }

    &-title{
        margin-bottom: rem-calc(8);
    }

    .tingle-modal-box {
        @include breakpoint(medium down) {
            display: flex;
        }

        &__content {
            padding: rem-calc(0);
            display: flex;

            @include breakpoint(large up) {
                padding: rem-calc(0);
            }

            @include breakpoint(medium down) {
                height: 100%;
            }
        }
    }
}
